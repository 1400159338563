<template>
  <div>
    <slideout
      dock="right"
      :size="nested ? '87%' : '900px'"
      :min-size="nested ? 400 : 900"
      :allow-resize="nested ? false : true"
      :append-to="nested ? '' : '#app'"
      :visible.sync="slideouts.activityLog.active"
    >
      <template v-slot:header>
        <h4 v-if="!isLogsInitiallyLoaded && isLoading" class="font-weight-bold pa-1" small>
          <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Offer Activity Logs ...
        </h4>
        <h4 v-else-if="offerId != null" class="font-weight-bold pa-1" small>
          <span>
            <i class="mr-2 fad fa-history"></i>Activity Logs:
            <u>{{ offer.name }}</u>
          </span>
        </h4>
        <div class="d-flex flex-row align-center">
          <div
            v-if="offerId != null"
            class="d-flex flex-row align-center mr-2 blue-grey lighten-5 px-2"
            style="gap: 0.5rem; border-radius: 0.35rem"
          >
            <div>
              <span class="fs-12px font-weight-bold mr-1 text--secondary">Created by</span>
              <user-avatar :user="offer.createdBy" icon showNoUser tooltipBottom></user-avatar>
            </div>
            <div>
              <span class="fs-12px font-weight-bold mr-1 text--secondary">Updated by</span>
              <user-avatar :user="offer.updatedBy" icon showNoUser tooltipBottom></user-avatar>
            </div>
          </div>
          <v-btn @click="slideouts.activityLog.active = false" icon>
            <i class="far fa-times"></i>
          </v-btn>
        </div>
      </template>
      <div style="background: #eceff1; height: 100%; overflow: auto" class="pr-5 inset-shadow">
        <v-timeline align-top dense style="min-height: 100%">
          <v-timeline-item v-for="(item, i) in entities" :key="i" color="white" right fill-dot>
            <template v-slot:icon>
              <user-avatar
                style="z-index: 100"
                :user="item.createdBy"
                icon
                :mini="false"
              ></user-avatar>
            </template>
            <v-card
              :color="
                item.type == ActivityLogType.Automatic.value ? 'blue-grey lighten-4' : 'white'
              "
            >
              <v-card-title class="fs-14px py-2">
                <span class="mr-2">
                  <span v-if="item.type == ActivityLogType.Automatic.value">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fad fa-bolt-auto"></i>
                        </div>
                      </template>
                      <span>Auto logged by Concordia</span>
                    </v-tooltip>
                  </span>
                  <span v-else-if="item.type == ActivityLogType.Manual.value">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fad fa-square-quote"></i>
                        </div>
                      </template>
                      <span>This note is manually added by user</span>
                    </v-tooltip>
                  </span>
                  <span v-else-if="item.type == ActivityLogType.RelatedActions.value">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fad fa-cog"></i>
                        </div>
                      </template>
                      <span>This is a log for an action performed by user</span>
                    </v-tooltip>
                  </span>
                </span>
                <dater :date="item.createDate" no-tooltip></dater>
              </v-card-title>
              <v-card-text v-if="item.type == ActivityLogType.Automatic.value">
                <v-expansion-panels multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="px-4 py-0" :ripple="true">
                      <v-row no-gutters justify="space-between">
                        <v-col cols="auto" class="d-flex align-center">
                          <span class="font-weight-bold">
                            <v-chip
                              style="
                                height: 22px;
                                min-width: 22px;
                                justify-content: center;
                                font-size: 12px;
                              "
                              color="blue-grey"
                              text-color="white"
                              class="mr-2"
                              >{{ item.variances.length }}</v-chip
                            >Change{{ item.variances.length > 1 ? "s" : "" }}
                          </span>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center"> </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="logged-changes-panel-content">
                      <div v-for="(changedProp, j) in item.variances" :key="j">
                        <v-divider class="mb-1"></v-divider>
                        <div class="py-2 px-4 mb-1">
                          <span class="d-flex align-center">
                            <v-chip small color="blue-grey" text-color="white" style="height: 22px">{{
                              changedProp.prop
                                .split(/(?=[A-Z])/)
                                .join(" ")
                                .replace(". ", " > ")
                            }}</v-chip>
                            <span class="font-weight-medium ml-1">was updated</span>
                          </span>
                          <v-row class="mt-1">
                            <v-col cols="12" md="6">
                              <div class="d-flex">
                                <span class="font-weight-bold mr-1">From:</span>
                                <!-- <manufacturer-logged-prop
                                  v-if="changedProp.prop == 'Manufacture'"
                                  v-model="changedProp.oldValue"
                                ></manufacturer-logged-prop> -->
                                <default-logged-prop
                                  v-model="changedProp.oldValue"
                                ></default-logged-prop>
                              </div>
                            </v-col>
                            <v-col cols="12" md="6">
                              <div class="d-flex">
                                <span class="font-weight-bold mr-1">To:</span>
                                <!-- <manufacturer-logged-prop
                                  v-if="changedProp.prop == 'Manufacture'"
                                  v-model="changedProp.newValue"
                                ></manufacturer-logged-prop> -->
                                <default-logged-prop
                                  v-model="changedProp.newValue"
                                ></default-logged-prop>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
              <v-card-text v-else-if="item.type == ActivityLogType.Manual.value">
                <div
                  class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0 secondary--text"
                  v-html="
                    item.body != null && item.body != ''
                      ? item.body
                      : `<i class='fad fa-empty-set opacity-64'></i>`
                  "
                />
              </v-card-text>
              <v-card-text v-else-if="item.type == ActivityLogType.RelatedActions.value">
                <related-actions-logs v-model="item.body"></related-actions-logs>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            v-if="!isEndReached"
            :key="'end-not-reached'"
            color="white"
            right
            fill-dot
          >
            <template v-slot:icon>
              <i class="fad fa-spinner-third fa-spin"></i>
            </template>
            <v-card-title v-intersect="onIntersect" class="fs-14px py-1 px-0">
              Fetching more activity logs...
            </v-card-title>
          </v-timeline-item>
          <v-timeline-item v-else :key="'end-reached'" color="white" right fill-dot>
            <template v-slot:icon>
              <i class="fad fa-flag-pennant"></i>
            </template>
            <v-card-title v-if="entities.length == 0" class="fs-14px py-1 px-0 text-secondary">
              No logs recorded yet!
            </v-card-title>
            <v-card-title v-else class="fs-14px py-1 px-0 text-secondary">End of Logs</v-card-title>
          </v-timeline-item>
        </v-timeline>
      </div>
      <template v-slot:footer>
        <v-card-actions>
          <v-btn text @click="slideouts.activityLog.active = false">
            <i class="fal fa-times mr-2"></i>
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="openNoteModal">
            <i class="far fa-plus mr-2"></i>
            Add Note/Review
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>

    <v-dialog v-model="modals.note.active" max-width="850px" style="z-index: 99999999">
      <v-card dense>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-plus-square mr-2"></i>Add Note / Review
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.note.valid" ref="addManualNote">
          <v-card-text>
            <v-container fluid class="pa-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.note.body"
                    title="Note"
                    subtitle="Add your notes or review for this offer"
                    showLabel
                    maxHeight="132px"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="modals.note.active = false" :disabled="modals.note.isLoading">
              <i class="fal fa-chevron-left mr-2"></i>Discard
            </v-btn>
            <v-btn
              color="info"
              :loading="modals.note.isLoading"
              :disabled="!validNoteString || modals.note.isLoading"
              @click="noteConfirmed()"
            >
              <i class="fal fa-check mr-2"></i> Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Enums from "../../../../plugins/enums";
import offerService from "../../services/resource-offers-service";
import RichTextEditor from "../../../Shared/components/RichTextEditor.vue";
import RelatedActionsLogs from "./RelatedActionsLogs.vue";
import DefaultLoggedProp from "./DefaultLoggedProp.vue";

export default {
  name: "offer-activity-log",
  components: {
    RichTextEditor,
    RelatedActionsLogs,
    DefaultLoggedProp,
  },
  data() {
    return {
      ActivityLogType: Enums.ActivityLogType,
      isLogsInitiallyLoaded: false,
      isEndReached: false,
      isIntersecting: false,
      isLoading: false,
      offerId: null,
      offer: null,
      entities: [],
      modals: {
        note: {
          body: null,
          valid: false,
          active: false,
          isLoading: false,
        },
      },
      slideouts: {
        activityLog: {
          active: false,
        },
      },
      options: {
        search: null,
        includeOffer: false,
        page: 0,
        itemsPerPage: 5,
        sortBy: "createDate",
        sortDesc: true,
      },
      storedSearch: null,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.offerId = this.cloneDeep(this.value);
  },
  computed: {
    offerUrl() {
      if (this.offerId == null) return "";
      return `offer/${this.offerId}`;
    },
    validNoteString() {
      if (!this.modals.note.active) return false;
      if (this.stripHtmlTags(this.modals.note.body) == "") return false;
      return true;
    },
  },
  methods: {
    openNoteModal() {
      this.modals.note = {
        body: null,
        valid: false,
        active: true,
        isLoading: false,
      };
    },
    noteConfirmed() {
      this.modals.note.isLoading = true;
      offerService
        .addActivityLog(this.offerId, this.modals.note.body)
        .then((resp) => {
          this.modals.note = {
            body: null,
            valid: false,
            active: false,
            isLoading: false,
          };
          resp.data.variances.forEach((variance) => {
            if (variance.prop == "ImageUrl") variance.prop = "Image";
          });
          this.entities.unshift(resp.data);
        })
        .catch((err) => {
          this.this.modals.note.isLoading = false;
          this.$handleError(err);
        });
    },
    stripHtmlTags(str) {
      if (str === null || str === "") return "";
      else str = str.toString();

      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(<([^>]+)>)/gi, "");
    },
    open(offer) {
      this.offer = offer;
      this.offerId = offer.id;
      this.isEndReached = false;
      this.entities = [];
      this.options = {
        search: null,
        includeOffer: false,
        page: 0,
        itemsPerPage: 5,
        sortBy: "createDate",
        sortDesc: true,
      };
      this.getData();
      this.slideouts.activityLog.active = true;
    },
    getData() {
      this.$log(">>>> getData - this.isEndReached", this.isEndReached);
      if (this.isEndReached || !this.slideouts.activityLog.active || this.isLoading) return;
      this.options.page++;
      this.$log(">>>> getData > PROCEEDED > PAGE", this.options.page);
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 0;
      this.storedSearch = optionsToSend.search;
      // if (!this.isLogsInitiallyLoaded) {
      //   optionsToSend.includeOffer = true;
      // }
      this.isLoading = true;
      offerService
        .getActivityLog(this.offerId, this.$clean(optionsToSend, true))
        .then((resp) => {
          if (!this.isLogsInitiallyLoaded) {
            this.isLogsInitiallyLoaded = true;
            // this.offer = resp.data.offer;
          }
          if (resp.data.items.length < this.options.itemsPerPage) {
            this.isEndReached = true;
          }
          this.$log("resp.data", resp.data, "isEndReached ", this.isEndReached);
          resp.data.items.forEach((element) => {
            element.variances.forEach((variance) => {
              if (variance.prop == "ImageUrl") variance.prop = "Image";
            });
            this.entities.push(element);
          });
          this.total = resp.data.total;
          this.isLoading = false;

          setTimeout(() => {
            this.$log(">>>> setTimeout - this.isIntersecting:", this.isIntersecting);
            if (this.isIntersecting && !this.isEndReached) {
              this.getData();
            }
          }, 500);
        })
        .catch((err) => {
          this.isLoading = false;
          this.$handleError(err);
        });
    },
    onIntersect(entries, observer) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].isIntersecting;
      this.$log(">>>> onIntersect - this.isIntersecting:", this.isIntersecting);
      if (this.isIntersecting) {
        this.getData();
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.offerId = this.cloneDeep(this.value);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.logged-changes-panel-content .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
